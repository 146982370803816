//vom alten System
function decryptCharcode(
  n: number,
  start: number,
  end: number,
  offset: number,
) {
  n = n + offset;
  if (offset > 0 && n > end) {
    n = start + (n - end - 1);
  } else if (offset < 0 && n < start) {
    n = end - (start - n - 1);
  }
  return String.fromCharCode(n);
}
// decrypt string
function decryptString(enc: string, offset: number) {
  let dec = '';
  const len = enc.length;
  for (let i = 0; i < len; i++) {
    const n = enc.charCodeAt(i);
    if (n >= 0x2b && n <= 0x3a) {
      dec += decryptCharcode(n, 0x2b, 0x3a, offset); // 0-9 . , - + / :
    } else if (n >= 0x40 && n <= 0x5a) {
      dec += decryptCharcode(n, 0x40, 0x5a, offset); // A-Z @
    } else if (n >= 0x61 && n <= 0x7a) {
      dec += decryptCharcode(n, 0x61, 0x7a, offset); // a-z
    } else {
      dec += enc.charAt(i);
    }
  }
  return dec;
}
// decrypt spam-protected emails
export function linkTo_UnCryptMailto(s: string, n: number) {
  return decryptString(s, n);
}
